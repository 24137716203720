// This script will be included on ALL user sites and /config

// I believe this is always on a page with common, and so inherits the polyfills.
// but woe to keeping this insync.
import { init as rumCollector } from '@sqs/rum-collector';
import StatusConstants from '@sqs/enums/StatusConstants';
import PageTypes from '@sqs/enums/PageTypes';

import {
  captureException,
  withScope
} from 'shared/utils/error-reporter/global-sentry';

const context = window.Static && window.Static.SQUARESPACE_CONTEXT;
const inFrame = window.top !== window;

// Whitelist approach to register app name based on PageTypes enum values.
const PageTypeToAppNameMap = Object.freeze({
  [PageTypes.COMMERCE_CART_V2]: 'commerce-cart'
});

function isInConfig() {
  const windowPathname = (window.location && window.location.pathname) || '';
  return !inFrame && /^\/config(\/.*)?$/.test(windowPathname);
}

/** All context values shared between /config and unauthenticated site visitors */
function getSharedContext() {
  return {
    inFrame,
    templateId: context.templateId || '',
    impersonatedSession: !!context.impersonatedSession,
    pageType: typeof context.pageType === 'number' ? context.pageType : -1
  };
}

/** All context under the `SQUARESPACE_CONTEXT`.website key */
function getWebsiteContext() {
  const { website = {} } = context;

  return {
    authenticUrl: website.authenticUrl || '',
    cloneable: !!website.cloneable,
    developerMode: !!website.developerMode,
    isHstsEnabled: !!website.isHstsEnabled,
    language: website.language || '',
    timeZone: website.timeZone || '',
    websiteId: website.id || '',
    websiteType: website.websiteType || -1
  };
}

/** All context under the `SQUARESPACE_CONTEXT.websiteSettings` key */
function getWebsiteSettingsContext() {
  const { websiteSettings = {} } = context;

  return {
    ampEnabled: !!websiteSettings.ampEnabled
  };
}

/** All context under the `SQUARESPACE_CONTEXT.collection` key */
function getCollectionContext() {
  const { collection = {} } = context;

  return {
    collectionType: collection.type || -1
  };
}

/**
 * Checks if a site was built on Squarespace 8
 *
 * 8 currently does not ship with a `templateVersion` value in the context when not in config mode.
 * This uses the `SECTION_CONTEXT` to ID 8 sites. It may be removable in the future.
 * 8 Jan 2020 @cellison
 */
function isUserSiteEight() {
  return window.Squarespace && 'SECTION_CONTEXT' in window.Squarespace;
}

/**
 * Gets the Squarespace version number of the site being viewed or edited
 *
 * @returns {string} the version number. returns `null` if version unknown
 */
function getVersionNumber() {
  if (context.hasOwnProperty('templateVersion')) {
    return context.templateVersion.replace('.', '_');
  } else if (isUserSiteEight()) {
    return '8';
  }
  return null;
}

/**
 * Gets the options for initializing the RUM collector
 *
 * @param {string} version the Squarespace version
 * @returns {undefined|Object} the config or `undefined` if Squarespace verison is unknown
 */
function getCollectorOptions(version) {
  const isConfig = isInConfig();
  const appNameForPageType = PageTypeToAppNameMap[context.pageType];
  const rumOptions = {
    appName:
      appNameForPageType || `v${version}-${isConfig ? 'config' : 'user-sites'}`,
    context: {
      ...getSharedContext(),
      ...getWebsiteContext(),
      ...getWebsiteSettingsContext(),
      ...getCollectionContext()
    },
    captureException: (error, extras) => {
      withScope(scope => {
        scope.setTag('project', 'rum-collector');
        if (extras !== undefined) {
          scope.setExtra('extras', extras);
        }
        captureException(error);
      });
    }
  };

  if (isConfig) {
    const isInternal =
      context.website.siteStatus.value === StatusConstants.INTERNAL;
    const { createdOn, id: memberId } = context.authenticatedAccount;

    rumOptions.context.isInternal = isInternal;
    rumOptions.context.createdOn = createdOn;
    rumOptions.context.memberId = memberId;
  }
  return rumOptions;
}

function bootstrap() {
  if (!__DEV__ && context) {
    const version = getVersionNumber();
    if (version === null) {
      return;
    }
    const rumOptions = getCollectorOptions(version);
    rumCollector(rumOptions);
  }
}

bootstrap();
